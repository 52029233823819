const addAtrributes = require('../functions/add-attributes');
const ErrorMessage = require('./error-message');

class Input {

    static index = 0;

    constructor(form, { element, label, class: classNames, validation, inside_label, error, datalist, ...attributes }) {
        this.index = Input.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_inp${this.index}`;
        this._attributes.id = this._field_id;
        this._inside_label = inside_label;
        this._error = null;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)) {
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        this._input = this._createInput(attributes);

        if (validation && typeof validation === 'function') this._validation = validation;
        if (label) this._addLabel(label);
        this._insertInput();
        if (datalist) this._addDatalist(datalist);
        if (error) this.show_error(error);

        this._input.addEventListener('input', () => {
            
            if (this._error) {
                // if this._input is a radio, then remove errors from all radios with the same name
                if (this._input.type === 'radio') {
                    const radios = this._form.fields.filter(obj => obj._attributes?.name === this._input.name);
                    radios.forEach(radio => radio.hide_error());
                } else {
                    this.hide_error();
                }
            }
            
        });

        Input.index++;
    }

    get element() {
        return this._element;
    }

    get input() {
        return this._input;
    }

    get validation() {
        return this._validation;
    }

    get hasError() {
        if (this._error) return true;
        return false;
    }

    show_error(message) {
        const newError = new ErrorMessage(message);
        if (this._error) {
            this.element.replaceChild(newError.element, this._error.element);
        } else {
            this.element.appendChild(newError.element);
        }
        this.element.classList.add('error');
        this._error = newError;
    }

    hide_error() {
        this.element.classList.remove('error');
        this._error?.element?.remove();
        this._error = null;
    }

    _createInput(attributes) {
        const input = document.createElement('input');
        if (!attributes.type) attributes.type = 'text';
        
        addAtrributes(input, attributes);
        return input;
    }

    _addLabel(labelContent) {
        if (this._inside_label) labelContent = `<span>${labelContent}</span>`;
        const html = `<label for="${this._field_id}">${labelContent}</label>`;
        this._element.innerHTML = html;
    }

    _insertInput() {
        if (['radio', 'checkbox'].includes(this._attributes.type)) {
            if (this._inside_label) {
                const label = this._element.querySelector('label');
                label.insertAdjacentElement('afterbegin', this._input);
            } else {
                this._element.insertAdjacentElement('afterbegin', this._input);
            }
        } else {
            this._element.appendChild(this._input);
        }
    }

    _addDatalist(datalist) {
        const { id, values } = datalist;

        if (!id) {
            console.warn(`In "${this._attributes.name}" field provide an ID for the <datalist> element. The dataslist was ignored.`);
        } else if (!Array.isArray(values) || !values.length) {
            console.warn(`In "${this._attributes.name}" field provide a non-empty "values" array for the <datalist> element. The datalist was ignored.`);
        } else {
            const datalistElem = `
                <datalist id="${id}">
                    ${values.map(value => `<option value="${value}"></option>`).join('')}
                </datalist>`;

            this.element.querySelector('input').setAttribute('list', id);
            this.element.insertAdjacentHTML('beforeend', datalistElem);
        }
    }

}

module.exports = Input;