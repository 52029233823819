class Meter {
    static index = 0;

    constructor(form, {element, label, class:classNames, content, ...attributes}){
        this.index = Meter.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_mtr${this.index}`;
        this._attributes.id = this._field_id;


        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)) {
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        if(!this._validateAttributes(attributes)) return;

        this._meter = this._createProgress(attributes);

        if (label) this._addLabel(label);
        if (content) this._meter.innerHTML = content;

        this._element.appendChild(this._meter);

        Meter.index++;
    }

    get element(){
        return this._element;
    }

    _validateAttributes(providedAttributes){
        const attributesToValidate = ['value', 'min', 'max', 'low', 'high', 'optimum'];

        for(const attr of attributesToValidate){
            const value = providedAttributes[attr];

            if(!value) continue;

            if(!['number', 'string'].includes(typeof value)){
                console.error(`The "${attr}" attribute must be a type of 'string' or 'number'`);
                return false;
            }
        }

       return true;
    }

    _createProgress(attributes){
        const meter = document.createElement('meter');

        for(const [key, value] of Object.entries(attributes)){
            meter.setAttribute(key, value);
        }
        return meter;
    }

    _addLabel(labelContent){
        const html = `<label for="${this._field_id}">${labelContent}</label>`;
        this._element.insertAdjacentHTML("afterbegin", html);
    }
}

module.exports = Meter;