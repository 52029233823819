function addAtrributes(element, attributes) {

    const BOOLEAN_ATTRIBUTES = [
        'disabled',
        'selected',
        'checked',
        'readonly',
        'required',
        'multiple',
        'autofocus',
        'hidden',
        'contenteditable',
        'spellcheck',
        'novalidate'
    ];

    for (const [key, value] of Object.entries(attributes)) {
        if (BOOLEAN_ATTRIBUTES.includes(key) && value === true) {
            element[key] = true;
        } else if (!BOOLEAN_ATTRIBUTES.includes(key)) {
            element.setAttribute(key, value);
        }
    }
}

module.exports = addAtrributes;