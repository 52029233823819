export default function init() {

    const menu = document.querySelector('body > header .menu');
    const hamburger = menu.querySelector('#hamburger');
    const socMedia = menu.querySelector('.social-media');
    const highlighter = menu.querySelector('.highlighter');

    hamburger.onclick = () => {
        hamburger.parentElement.classList.toggle('active');
        updateDisplay();
    };

    const mediaQuery = window.matchMedia('(max-width: 600px)');
    mediaQuery.addEventListener('change', updateDisplay);

    initHighlighter();

    function updateDisplay() {
        if (hamburger.parentElement.classList.contains('active')) {
            socMedia.style.display = 'flex';
        } else if (window.innerWidth < 600) {
            socMedia.style.display = 'none';
        } else {
            socMedia.style.display = 'flex';
        }
    }

    function initHighlighter() {
        const links = menu.querySelectorAll('nav a');

        const activeLink = menu.querySelector('nav a.active');

        if (activeLink) {
            setTimeout(() => {

                const initialLeft = `${activeLink.offsetLeft + activeLink.offsetWidth / 2}px`;

                highlighter.animate([
                    { width: 0, left: initialLeft },
                    { width: `${activeLink.offsetWidth}px`, left: initialLeft }
                ], {
                    duration: 300,
                    fill: 'forwards'
                });

                set(activeLink);
            }, 500);
        }

        links.forEach(link => {
            link.onmouseover = async () => {
                set(link);
            };
        });

        function set(link) {
            let newWidth;
            const newLeft = `${link.offsetLeft + link.offsetWidth / 2}px`;

            if (link.classList.contains('logo_home') || link.classList.contains('donate')) {
                newWidth = '4px';
            } else {
                newWidth = `${link.offsetWidth}px`;
            }

            highlighter.animate([
                { width: highlighter.style.width, left: highlighter.style.left },
                { width: newWidth, left: newLeft }
            ], {
                duration: 400,
                fill: 'forwards',
                easing: 'cubic-bezier(0.5, 0, 0, 1)'
            });
        }
    }
}


