const AJAXForm = require('../modules/ajax-forms/ajaxforms');

export default function () {

    const token = document.querySelector('meta[name="csrf"]').getAttribute('content');

    const settings = {
        container: '#application', // CSS Selector // obligatory
        form_element: true,
        callback: doSomethignWithResponse, // recommended,
        action: '/api/send',
        autocomplete: 'on',
        method: 'post',
        novalidate: false,
        json: true,
        ajax: true,
        headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': token
        },
        fields: [
            {
                element: 'fieldset',
                content: [
                    {
                        element: 'input', // obligatory
                        type: 'text',
                        name: 'user_name', // obligatory
                        required: true,
                        label: 'Name:',
                        class: 'fld'
                    },
                    {
                        element: 'input', // obligatory
                        type: 'email',
                        name: 'user_email', // obligatory
                        required: true,
                        label: 'E-mail:',
                        class: 'fld'
                    },
                    {
                        element: 'input', // obligatory
                        type: 'tel',
                        name: 'user_phone', // obligatory
                        required: true,
                        label: 'Phone:',
                        class: 'fld'
                    }
                ]
            },
            {
                element: 'textarea',
                name: 'user_message',
                required: true,
                label: 'A few words about yourself:',
                rows: 4,
                class: 'fld'
            },
            {
                element: 'button',
                content: 'Apply',
                class: ['btn', 'blue'],
                disabled: false
            }
        ]
    };

    const getFormBtn = document.querySelector('#get_form');
    const container = document.querySelector('#application');

    getFormBtn.onclick = async () => {
        const form = new AJAXForm(settings);
        console.log(form);
        console.log(form.form.element.scrollHeight);

        getFormBtn.style.transformOrigin = 'center';
        getFormBtn.animate({ opacity: 0, height: 0 }, {
            duration: 650,
            easing: 'ease-in-out',
            fill: 'forwards'
        });

        getFormBtn.style.pointerEvents = 'none';


        await container.animate({ height: form.form.element.scrollHeight + 'px' }, {
            duration: 650,
            easing: 'cubic-bezier(0.5, 0, 0, 1)',
            fill: 'forwards'
        }).finished;

        container.style.height = 'auto';
        container.style.overflow = 'visible';

    }

}

function doSomethignWithResponse(data) {

    if (data.success) {
        const apply = document.querySelector('#apply');

        const html = `<div class="thankyou">
            <h3>Thank you for your interest!</h3>
            <p> We will get back to you as soon as possible.</p>
        </div>`;

        apply.innerHTML = html;
    }

}