
export default function () {

    const section = document.querySelector('body > main > section');
    const btnOpen = document.querySelector('.expand');
    const formContainer = document.querySelector('#payment-form');
    const input = section.querySelector(':scope > footer input');

    btnOpen.addEventListener('click', async (e) => {
        e.preventDefault();

        const amount = input.value * 100; // Convert to cents
        addStripe(amount, e.target.dataset.key);
        section.classList.add('card');
        formContainer.style.height = 'auto';
        formContainer.style.overflow = 'visible';
        btnOpen.style.display = 'none';
        input.disabled = true;
    });

}

async function addStripe(amount, key) {
    const stripe = Stripe(key);

    const token = document.querySelector('meta[name="csrf"]').content;

    // Fetch the PaymentIntent client secret from your backend
    const response = await fetch("/create-payment-intent", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token
        },
        body: JSON.stringify({ amount })
    });

    const { clientSecret } = await response.json();

    // Set up Stripe Elements with the clientSecret
    const elements = stripe.elements({
        clientSecret, // Required for Payment Element
    });

    // Payment Request Button (for Apple Pay and Google Pay)
    const paymentRequest = stripe.paymentRequest({
        country: 'US', // Update this based on your business location
        currency: 'eur',
        total: {
            label: 'Donation',
            amount: amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
    });

    const paymentRequestButton = elements.create('paymentRequestButton', {
        paymentRequest,
    });

    // Check if the browser supports the Payment Request API
    paymentRequest.canMakePayment().then(function (result) {
        if (result) {
            // Mount the Payment Request Button if available
            paymentRequestButton.mount('#payment-request-button');
        } else {
            // Hide the button if not available
            document.getElementById('payment-request-button').style.display = 'none';
        }
    });

    // Payment Element (for other payment methods like card, EPS, Bancontact)
    const paymentElement = elements.create('payment', {
        layout: {
            type: 'tabs',
        },
    });

    paymentElement.mount('#payment-element');

    // Handle form submission
    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event) => {
        event.preventDefault();

        // Submit the Payment Element first
        const { error: submitError } = await elements.submit();

        if (submitError) {
            console.error('Error in elements.submit():', submitError.message);
            return;
        }

        // Proceed with confirmPayment if submit() was successful
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3001/thank-you',
            },
            redirect: 'if_required', // Prevent automatic redirection
            clientSecret
        });

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            handlePaymentSuccess(paymentIntent); // Your custom function to handle success
        }

        if (error) {
            handlePaymentError(error); // Your custom function to handle errors
        }
    });
}

function handlePaymentSuccess() {
    const section = document.querySelector('body > main > section');
    section.innerHTML = '<h1>Thank you for your donation!</h1>';
}

function handlePaymentError() {
    // Show an error message to the user
    alert('Payment failed');
}