const addAtrributes = require('../functions/add-attributes');
const components = {
    button: require('./button'),
    textarea: require('./textarea'),
    input: require('./input'),
    select: require('./select')
}

class Fieldset{

    static index = 0;

    constructor(form, { element, legend, content, validation, class: classNames, error, ...attributes }){
        this.index = Fieldset.index;
        this._form = form;
        this._element = document.createElement('fieldset');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_flds${this.index}`;
        this._attributes.id = this._field_id;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)) {
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        addAtrributes(this._element, attributes);
        // this._fieldset = this._createFieldset(attributes);

        if (legend) this._addLegend(legend);
        if (content) this._addContent(content);

        // this._element.appendChild(this._element);
        Fieldset.index++;
    }

    get element(){
        return this._element;
    }

    // _createFieldset(attributes){
    //     const fieldset = document.createElement('fieldset');
    //     addAtrributes(fieldset, attributes);
    //     return fieldset;
    // }

    _addLegend(legendContent){
        const html = `<legend>${legendContent}</legend>`;
        this._fieldset.insertAdjacentHTML('afterbegin', html);
    }

    _addContent(fields){
        for(const field of fields){
            const child = new components[field.element](this._form, field);
            this._form.fields.push(child);
            this._element.appendChild(child.element);
        }
    }
}

module.exports = Fieldset;