import getImgTag from '../../../../../core/functions/get-img-tag';

export default function () {

    console.log('projects.js loaded');

    initProjectsMap();

}

function initProjectsMap() {

    const mapWrap = document.querySelector('#map_wrap');
    const mapContainer = mapWrap.firstElementChild;
    const nav = mapContainer.querySelector('nav');
    const content = [...document.querySelectorAll('body > main > section.card')];
    const title = document.querySelector('body > main > h1');
    const background = document.querySelector('#location_background');

    const mapTop = mapContainer.getBoundingClientRect().top;

    let bgImages = [];
    let middle = '50vh';

    // if iOS
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        middle = window.visualViewport.height / 2 + 'px';
    }

    [...nav.children].forEach(item => {

        const imgObj = JSON.parse(item.firstElementChild.dataset.img);
        item.img_tag = getImgTag(imgObj);
        background.insertAdjacentHTML('beforeend', item.img_tag);
        item.bg_img = background.lastElementChild;
        bgImages.push(item.bg_img);

        let initialWidth = '100%';
        if (window.innerWidth < window.innerHeight || window.innerWidth <= 700) {
            initialWidth = '140%';
        }

        mapContainer.initialPosition = {
            width: initialWidth,
            transform: `translate3d(0, 0, 0)`
        };

        item.onclick = (e) => {
            e.preventDefault();
            window.removeEventListener('popstate', closeProject);
            openProject(item);
        }

    });

    content.forEach(item => {
        const closeBtn = item.querySelector('button');
        closeBtn.onclick = closeProject;
    });

    document.addEventListener('keydown', e => {
        if (e.key === 'Escape' || e.key === 'Backspace') closeProject();
    });

    async function openProject(item) {

        [...nav.children].forEach(navItem => {
            if (navItem === item) {
                item.onclick = e => {
                    e.preventDefault();
                    closeProject();
                };
            } else {
                navItem.onclick = null;
                navItem.style.cursor = 'default';
            }
        });

        item.onclick = e => {
            e.preventDefault();
            closeProject();
        };

        const mapBox = mapContainer.getBoundingClientRect();
        const mapHeight = mapBox.height;

        const location = JSON.parse(item.firstElementChild.dataset.location);
        const locationImage = item.firstElementChild.firstElementChild;
        const imgWidth = locationImage.width;
        const imgHeight = locationImage.height / mapHeight * 100 + '%';

        let mapZoom = 2;
        let maskLeft = 0.15;
        let maskSize = '15vw';
        let leftShift = 0;

        if (window.innerWidth < window.innerHeight) {
            mapZoom = 3;
            leftShift = window.innerWidth * 0.2;
            maskSize = '25vw';
            maskLeft = 0.1;
        }

        // todo: replace background image
        item.bg_img.style.display = 'block';

        mapWrap.classList.add('masking');

        const leftCoord = maskLeft * 100 + 'vw';
        const left = `calc(-${location.left * 100}% + ${leftShift}px + ${leftCoord} + (${maskSize} / 2) - ${location.width / 2 * 100}%)`;
        const top = `calc(${middle} - ${mapTop}px - ${location.top * 100}% - (${imgHeight} / 2))`;

        const end = {
            width: `${mapZoom * 100}%`,
            transform: `translate3d(${left}, ${top}, 0)`
        };

        mapContainer.animate([mapContainer.startPosition, end], {
            duration: 1000,
            easing: 'cubic-bezier(.5, 0, 0, 1)',
            fill: 'forwards'
        });

        title.animate({ opacity: 0 }, {
            duration: 500,
            easing: 'ease-in-out',
            fill: 'forwards'
        });

        mapContainer.startPosition = end;


        // hide navigation titles
        [...nav.children].forEach(item => {
            item.querySelector('figcaption').animate({ opacity: 0 }, {
                duration: 500,
                easing: 'ease-in-out',
                fill: 'forwards'
            });
        });

        setTimeout(() => {
            const id = item.getAttribute('href');

            content.forEach(async card => {

                if ('#' + card.id === id) {
                    card.classList.add('active');
                    card.animate({ opacity: 0 }, {
                        duration: 0,
                        fill: 'forwards'
                    });
                    await card.animate({ opacity: 1 }, {
                        duration: 500,
                        easing: 'ease-in-out',
                        fill: 'forwards'
                    }).finished;

                    window.addEventListener('popstate', closeProject, { once: true });

                } else {
                    card.classList.remove('active');
                }
            });

        }, 300);

    }

    async function closeProject() {

        window.removeEventListener('popstate', closeProject);

        if (window.history.pushState) {
            window.history.pushState('', '/', window.location.pathname);
        } else {
            window.location.hash = '';
        }

        mapWrap.classList.remove('masking');

        mapContainer.startPosition = mapContainer.initialPosition;

        mapContainer.animate(mapContainer.initialPosition, {
            duration: 1000,
            easing: 'cubic-bezier(.5, 0, 0, 1)',
            fill: 'forwards'
        });

        title.animate({ opacity: 1 }, {
            duration: 500,
            easing: 'ease-in-out',
            fill: 'forwards'
        });

        // show navigation titles
        [...nav.children].forEach(item => {
            item.querySelector('figcaption').animate({ opacity: 1 }, {
                duration: 500,
                easing: 'ease-in-out',
                fill: 'forwards'
            });
        });

        setTimeout(() => {
            bgImages.forEach(bg => bg.style.display = 'none');
        }, 900);

        const activeCard = content.find(card => card.classList.contains('active'));

        await activeCard.animate({ opacity: 0 }, {
            duration: 500,
            easing: 'ease-in-out',
            fill: 'forwards'
        }).finished;

        await document.querySelector('body > main').animate({ paddingTop: 0 }, {
            duration: 500,
            easing: 'ease-in-out'
        }).finished;

        activeCard.classList.remove('active');

        [...nav.children].forEach(item => {
            item.onclick = (e) => {
                e.preventDefault();
                openProject(item);
            };
            item.style.cursor = 'pointer';
        });

    }
}