const AJAXForm = require('../modules/ajax-forms/ajaxforms');

export default function () {

    const token = document.querySelector('meta[name="csrf"]').getAttribute('content');

    const settings = {
        container: 'body > main > section', // CSS Selector // obligatory
        form_element: true,
        callback: doSomethignWithResponse, // recommended,
        action: '/api/send',
        autocomplete: 'on',
        method: 'post',
        novalidate: false,
        json: true,
        ajax: true,
        headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': token
        },
        fields: [
            {
                element: 'input', // obligatory
                type: 'text',
                name: 'user_name', // obligatory
                required: true,
                label: 'Name:',
                class: 'fld'
            },
            {
                element: 'input', // obligatory
                type: 'email',
                name: 'user_email', // obligatory
                required: true,
                label: 'E-mail:',
                class: 'fld'
            },
            {
                element: 'textarea',
                name: 'user_message',
                required: true,
                label: 'Message:',
                rows: 4,
                class: 'fld'
            },
            {
                element: 'button',
                content: 'Send Message',
                class: ['btn', 'blue'],
                disabled: false
            }
        ]
    };

    new AJAXForm(settings);

}

function doSomethignWithResponse(data) {

    if (data.success) {
        const form = document.querySelector('body > main > section > form');

        const html = `<div class="thankyou">
            <h3>Thank you for your interest!</h3>
            <p> We will get back to you as soon as possible.</p>
        </div>`;

        form.innerHTML = html;
    }

}