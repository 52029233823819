const component = {
    button: require('./button'),
    fieldset: require('./fieldset'),
    textarea: require('./textarea'),
    input: require('./input'),
    select: require('./select'),
    output: require('./output'),
    progress: require('./progress'),
    meter: require('./meter'),
    text: require('./text')
}

class Form {

    static index = 0;

    constructor({ fields, form_element, attributes, ...settings }) {
        this.index = Form.index;
        this._element = document.createElement(form_element === false ? 'div' : 'form');
        this._attributes = attributes;
        this.properties = [
            {
                name: 'action',
                attribute: true,
                obligatory: true,
                type: 'string',
                default: null,
                values: null,
                description: 'URL used for the form action',
                valid: function (value) {
                    if (typeof value === this.type) return true;
                    return false;
                }
            },

            {
                name: 'autocomplete',
                attribute: true,
                obligatory: false,
                type: 'string',
                default: null,
                values: ['off', 'on'],
                description: 'Automatically completing input value by the browser',
                valid: function (value) {
                    if (this.values.includes(value)) return true;

                    console.error(`Invalid value for '${this.name}' attribute. Possible values: ${this.values.join(', ')}`);
                    return false;
                }
            },

            {
                name: 'enctype',
                attribute: true,
                obligatory: false,
                type: 'string',
                default: null,
                values: ['application/x-www-form-urlencoded', 'multipart/form-data', 'text/plain'],
                description: 'Specifies how the form-data should be encoded when submitting it to the server',
                valid: function (value) {
                    if (this.values.includes(value)) return true;

                    console.error(`Invalid value for '${this.name}' attribute. Possible values: ${this.values.join(', ')}`);
                    return false;
                }
            },

            {
                name: 'method',
                attribute: true,
                obligatory: false,
                type: 'string',
                default: null,
                values: ['get', 'post', 'dialog'],
                description: 'HTTP method used for the form',
                valid: function (value) {
                    value = value.toLowerCase();
                    if (this.values.includes(value)) return true;

                    console.error(`Invalid value for '${this.name}' attribute. Possible values: ${this.values.join(', ')}`);
                    return false;
                }
            },

            {
                name: 'name',
                attribute: true,
                obligatory: false,
                type: 'string',
                default: null,
                values: null,
                description: 'The name of the form',
                valid: function (value) {
                    if (typeof value === this.type) return true;
                    if (value) return true;

                    console.error(`Invalid type of '${this.name}' attribute. Acceptable type: ${this.type}`)
                    return false;
                }
            },

            {
                name: 'novalidate',
                attribute: true,
                obligatory: false,
                type: 'boolean',
                default: null,
                values: [true, false],
                description: 'No form validation',
                valid: function (value) {
                    if (this.values.includes(value)) return true;

                    console.error(`Invalid value for '${this.name}' attribute. Possible values: ${this.values.join(', ')}`);
                    return false;
                }
            },

            {
                name: 'target',
                attribute: true,
                obligatory: false,
                type: 'string',
                default: null,
                values: ['_self', '_blank', '_parent', '_top'],
                description: 'Indicates where to display the response after submitting the form',
                valid: function (value) {
                    if (this.values.includes(value)) return true;

                    console.error(`Invalid value for '${this.name}' attribute. Possible values: ${this.values.join(', ')}`);
                    return false;
                }
            },

            {
                name: 'class',
                attribute: true,
                obligatory: false,
                type: ['string', 'array'],
                default: null,
                values: null,
                description: 'A space-separated list of the classes of the element',
                valid: function (value) {
                    if (typeof value == 'string' || Array.isArray(value)) return true;

                    console.error(`Invalid type of '${this.name}' attribute. Acceptable types: ${this.type.join(', ')}`)
                    return false;

                }
            },

            {
                name: 'id',
                attribute: true,
                obligatory: false,
                type: 'string',
                default: null,
                values: null,
                description: 'Defines a unique identifier (ID) which must be unique in the whole document',
                valid: function (value) {
                    if (typeof value === this.type) return true;

                    console.error(`Invalid type of '${this.name}' attribute. Acceptable type: ${this.type}`)
                    return false;
                }
            }
        ];

        this._fields = [];
        this.ok = true;

        for (const prop of this.properties) {
            const value = settings[prop.name];

            if (prop.obligatory && !value) {
                console.error(`You must add "${prop.name}" propery with a ${prop.type} value.\n"${prop.name}" — ${prop.description}.${prop.values ? `\nPossible values for "${prop.name}": ${prop.values.join(', ')}` : ''}`);
                this.ok = false;
                return;
            } else {
                if (value) {
                    if (prop.valid(value)) {
                        const isFormTag = this._element.tagName === 'FORM';
                        const isDivTag = this._element.tagName === 'DIV';
                        const isClassOrId = ['class', 'id'].includes(prop.name);

                        if ((prop.attribute && isFormTag) || (isDivTag && isClassOrId)) {
                            const valueString = Array.isArray(value) ? value.join(' ') : value;
                            this.element.setAttribute(prop.name, valueString);
                        } else {
                            this.attributes[prop.name] = value;
                        }
                    } else {
                        if (prop.obligatory) {
                            this.ok = false;
                            return;
                        }
                    }
                }
            }
        }

        if(this._attributes){
            for(const [key, value] of Object.entries(this._attributes)){
                this.element.setAttribute(key, value);
            }
        }

        this._appendFieldsToForm(fields);
        Form.index++;

    }

    get element() {
        return this._element;
    }

    get attributes() {
        return this._attributes;
    }

    get fields() {
        return this._fields;
    }

    _appendFieldsToForm(fields) {
        for (const field of fields) {
            const child = new component[field.element](this, field);

            if (child.element){
                this._fields.push(child);
                this.element.appendChild(child.element);
            }
        }
    }

}

module.exports = Form;