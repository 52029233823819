import cookies from './modules/cookies';
import header from './modules/header';
import projects from './pages/projects';
import volunteers from './pages/volunteers';
import contact from './pages/contact';
import donate from './pages/donate';

const page = {
    projects,
    volunteers,
    contact,
    donate
}

document.addEventListener('DOMContentLoaded', () => {
    
    const currentPage = determineCurrentPage();
    
    globalFunctions();

    if (page[currentPage]) page[currentPage]();

});


function determineCurrentPage() {

    const templateName = document.querySelector('body > main').dataset.template;
    return templateName || undefined;

}

function globalFunctions() {
    
    header();
    cookies();
    
}
