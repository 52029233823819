const addAtrributes = require('../functions/add-attributes');
const ErrorMessage = require('./error-message');

class Textarea{

    static index = 0;

    constructor(form, { element, label, content, validation, class: classNames, error, ...attributes }){
        this.index = Textarea.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_txt${this.index}`;
        this._attributes.id = this._field_id;
        this._error = null;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)) {
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        this._textarea = this._createTextarea(attributes);
        this._element.appendChild(this._textarea);

        if (validation && typeof validation === 'function') this._validation = validation;
        if (content) this._textarea.innerHTML = content;
        if (label) this._addLabel(label);
        if (error) this.show_error(error);


        this._textarea.addEventListener('input', () => {
            if (this._error) this._hideError();
        });

        Textarea.index++;
    }

    get element(){
        return this._element;
    }

    get input(){
        return this._textarea;
    }

    get validation(){
        return this._validation;
    }

    get hasError(){
        if(this._error) return true;
        return false;
    }

    show_error(message){
        const newError = new ErrorMessage(message);
        if (this._error) {
            this.element.replaceChild(newError.element, this._error.element);
        } else {
            this.element.appendChild(newError.element);
        }
        this.element.classList.add('error');
        this._error = newError;
    }

    _createTextarea(attributes){
        const textarea = document.createElement('textarea');
        addAtrributes(textarea, attributes);
        return textarea;
    }

    _addLabel(labelContent){
        const html = `<label for="${this._field_id}">${labelContent}</label>`;
        this._element.insertAdjacentHTML('afterbegin', html);
    }

    _hideError() {
        this.element.classList.remove('error');
        this._error?.element?.remove();
        this._error = null;
    }

}

module.exports = Textarea;