class Text{
    static index = 0;

    constructor(form, {element, class:classNames, content, ...attributes}){
        if(!content){
            console.warn("Text element cannot be empty.");
            return;
        }

        this.index = Text.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_txt${this.index}`;
        this._attributes.id = this._field_id;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)){
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        this._text = this._createText(attributes);
        this._text.innerHTML = content;
        this._element.appendChild(this._text);

        Text.index++;
    }

    get element(){
        return this._element;
    }

    _createText(attributes){
        const text = document.createElement('p');

        for(const [key, value] of Object.entries(attributes)){
            text.setAttribute(key, value);
        }

        return text;
    }
}

module.exports = Text;