const addAtrributes = require('../functions/add-attributes');

class Button {

    static index = 0;

    constructor(form, { element, content, class: classNames, ...attributes }) {
        this.index = Button.index;
        this._attributes = attributes;
        this._form = form;
        this._element = this._createButton();

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)) {
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        this.element.innerHTML = content;

        Button.index++;
    }

    get element() {
        return this._element;
    }

    _createButton() {
        const button = document.createElement('button');

        if (!this._attributes.type) this._attributes.type = 'submit';
        addAtrributes(button, this._attributes);
        return button;
    }
}

module.exports = Button;