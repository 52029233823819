class Output{
    static index = 0;

    constructor(form, {element, class:classNames, content, ...attributes}){
        this.index = Output.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_otp${this.index}`;
        this._attributes.id = this._field_id;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)){
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }


        this._output = this._createOutput(attributes);
        this._output.innerHTML = content;
        this._element.appendChild(this._output);


        Output.index++;
    }

    get element(){
        return this._element;
    }

    _createOutput(attributes){
        const output = document.createElement('output');

        for(const [key, value] of Object.entries(attributes)){
            output.setAttribute(key, value);
        }
        return output;
    }
}

module.exports = Output;