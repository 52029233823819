const addAtrributes = require('../functions/add-attributes');

class Select {
    static index = 0;

    constructor(form, {element, label, options, class: classNames, ...attributes}){
        this.index = Select.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_slct${this.index}`;
        this._attributes.id = this._field_id;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)){
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        this._select = this._createSelect(attributes);
        this._element.appendChild(this._select);

        if (label) this._addLabel(label);
        if (options) this._addContent(options);

        Select.index++;
    }

    get element(){
        return this._element;
    }

    get input(){
        return this._select;
    }

    _createSelect(attributes){
        const select = document.createElement('select');
        addAtrributes(select, attributes);
        return select;
    }

    _addLabel(labelContent){
        const html = `<label for="${this._field_id}">${labelContent}</label>`;
        this.element.insertAdjacentHTML("afterbegin", html)
    }

    _addContent(options){
        for(const optionObj of options){
            if('optgroup' in optionObj){
                const {optgroup:name, options} = optionObj;

                if(hasNestedOptgroup(options)){
                    continue;
                }

                const optgroup = `<optgroup label="${name}">${options.map(option => addOption(option)).join('')}</optgroup>`;
                this._select.insertAdjacentHTML("beforeend", optgroup);
            } else {
                const option = addOption(optionObj);
                this._select.insertAdjacentHTML("beforeend", option);
            }
        }

        function hasNestedOptgroup(options){
            for(const option of options){
                if(option.optgroup){
                    return true;
                }
            }
            return false;
        }

        function addOption(optionAttributes){
            const {value, ...attributes} = optionAttributes

            const option = document.createElement('option');
            option.innerHTML = value;

            for (const [key, value] of Object.entries(attributes)) {
                if (key === 'required' && value === true) {
                    option.required = true;
                } else {
                    option.setAttribute(key, value);
                }
            }
            return option.outerHTML;
        }
    }
}

module.exports = Select;