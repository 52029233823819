class Progress {
    static index = 0;

    constructor(form, {element, label, class:classNames, content, ...attributes}){
        this.index = Progress.index;
        this._form = form;
        this._element = document.createElement('div');
        this._attributes = attributes;
        this._field_id = attributes.id ? attributes.id : `frm${form.index}_pgrs${this.index}`;
        this._attributes.id = this._field_id;

        if (classNames) {
            if (typeof classNames === 'string') {
                this._element.classList.add(classNames);
            } else if (Array.isArray(classNames)) {
                this._element.classList.add(...classNames);
            } else {
                console.warn(`Provided class "${classNames}" is neither a string nor an array. Ignoring it.`);
            }
        }

        if(!this._validateAttributes(attributes)) return;

        this._progress = this._createProgress(attributes);

        if (label) this._addLabel(label);
        if (content) this._progress.innerHTML = content;

        this._element.appendChild(this._progress);

        Progress.index++;
    }

    get element(){
        return this._element;
    }

    _validateAttributes(providedAttributes){
        const attributesToValidate = ['value', 'max'];

        for(const attr of attributesToValidate){
            const value = providedAttributes[attr];

            if(!value) continue;

            if(!['number', 'string'].includes(typeof value)){
                console.error(`The "${attr}" attribute must be a type of 'string' or 'number'`);
                return false;
            }
        };

        return true;
    }

    _createProgress(attributes){
        const progress = document.createElement('progress');

        for(const [key, value] of Object.entries(attributes)){
            progress.setAttribute(key, value);
        }
        return progress;
    }

    _addLabel(labelContent){
        const html = `<label for="${this._field_id}">${labelContent}</label>`;
        this._element.insertAdjacentHTML("afterbegin", html)
    }
}

module.exports = Progress;