const Form = require('./components/form');

class AJAXForm {

    constructor({ container, callback, redirect, json, nonce, ajax, ...settings }) {
        this.container = container;
        this.callback = callback;
        this.json = json;
        this.redirect = redirect;
        this.settings = settings;
        this.nonce = nonce;
        this.ajax = ajax;
        this.form = new Form(settings);

        if (this.form.ok) this._insertFormIntoDOM();

        const submitBtns = this.element.querySelectorAll('[type="submit"]');
        submitBtns.forEach(button => {
            button.addEventListener('click', this._processFields.bind(this))
        });
    }

    get element() {
        return this.form._element;
    }

    _insertFormIntoDOM() {
        try {
            const container = document.querySelector(this.container);
            if (!container) throw new Error('Container element not found.');
            container.appendChild(this.form.element);
            // this.form.element.onsubmit = this.callback;
            this.form.element.onsubmit = (e) => {
                e.preventDefault();
                this._processFields();
            };
        }
        catch (error) {
            console.error(error);
        }
    }

    async _processFields(e) {

        if (!e) return;
        
        if (typeof this.ajax == 'boolean' && !this.ajax) {
            e.preventDefault();
            return;
        }

        const clickedBtn = e.target;
        const fieldsWithName = this._getFieldsWithName(this.form.fields);
        const formValues = this._validateFormFields(fieldsWithName);

        if (!this.form._element.checkValidity()) return;

        if (!formValues) return;
        if (clickedBtn.name) formValues[clickedBtn.name] = [clickedBtn.value];

        const serverResponse = await this._sendData(formValues);
        if (this.callback) this.callback(serverResponse);

        if (['string', 'object'].includes(typeof this.redirect)) {
            this._redirectUser(serverResponse);
        }

    }

    _getFieldsWithName(formElements) {
        let obj = {}
        for (const elem of formElements) {
            if (!elem.input || !elem.input.name) continue;
            addValue(elem.input.name, elem);
        }

        return obj;

        function addValue(key, value) {
            if (!obj[key]) obj[key] = [value];
            else if (Array.isArray(obj[key])) obj[key].push(value);
        }
    }

    _validateFormFields(fieldsWithName) {
        const radioGroups = {};
        let areValid = true;
        let validValuesObj = {};

        // Pre-determine the checked status of each radio group
        for (const [field_name, inputs] of Object.entries(fieldsWithName)) {
            for (const elem of inputs) {
                if (elem.input.type === 'radio') {
                    if (!radioGroups[field_name]) {
                        radioGroups[field_name] = { checked: false, value: null };
                    }
                    if (elem.input.checked) {
                        radioGroups[field_name] = { checked: true, value: elem.input.value };
                    }
                }
            }
        }

        // Main validation loop
        for (const [field_name, inputs] of Object.entries(fieldsWithName)) {
            let values = [];

            for (const elem of inputs) {
                // Check if the field is required and not filled/checked
                if (elem.input.required && (
                    (elem.input.type === 'radio' && !radioGroups[field_name].checked) ||
                    (elem.input.type === 'checkbox' && !elem.input.checked) ||
                    (elem.input.type !== 'radio' && elem.input.type !== 'checkbox' && !elem.input.value.trim())
                )) {
                    areValid = false;
                    elem.show_error('This field is required.');
                }

                if (elem.hasError) areValid = false;

                // Value validation using validation() method, if it exists
                if (typeof elem.validation === 'function') {
                    const validationResponse = elem.validation(elem.input);
                    if (!validationResponse.success) {
                        areValid = false;
                        elem.show_error(validationResponse.message);
                    }
                }

                // For checkboxes, push their checked status, else push their value
                if (elem.input.type === 'checkbox') {
                    if (elem.input.value && elem.input.checked) {
                        values.push(elem.input.value);
                    } else {
                        values.push(elem.input.checked);
                    }
                    // remove false values from the array
                    values = values.filter(value => value);
                } else if (elem.input.type !== 'radio') {
                    values.push(elem.input.value);
                }
            }

            // Add the value of the checked radio button if the group is checked
            if (radioGroups[field_name] && radioGroups[field_name].checked) {
                values.push(radioGroups[field_name].value);
            }

            if (values.length) validValuesObj[field_name] = values;
        }

        return areValid ? validValuesObj : false;
    }


    async _sendData(formValues) {

        let options = {
            method: this.settings.method
        };
        options.headers = {
            "Content-Type": "application/json"
        }
        if (this.settings.headers) {
            options.headers = this.settings.headers;
        }
        
        if (this.json) {
            options.body = JSON.stringify(formValues);
        } else {
            options.body = this._convertToFormDataObject(formValues);
        }

        const response = await fetch(this.settings.action, options)
        const data = await response.json();

        return data;
    }

    _redirectUser({ success, data }) {
        if (typeof this.redirect === 'object') {

            for (const key of Object.keys(this.redirect)) {
                if (!['url', 'params'].includes(key)) {
                    console.error(`Unknown key '${key}' in 'redirect' object.`)
                    return;
                }
            }

            let { url, params } = this.redirect;

            if (data && params) {
                url += '?';

                const values = Object.entries(data).map(([key, value]) => `${key}=${value}`);
                url += values.join('&');
            }
            window.location = encodeURI(url);
            return;
        }

        window.location = encodeURI(this.redirect);

    }

    _convertObjectToFormData(data) {
        const formObj = new FormData();
        for (const [key, value] of Object.entries(data)) {
            if (Array.isArray(value)) {
                value.forEach(val => {
                    formObj.append(key, val)
                })
            } else formObj.append(key, value);
        }
        return formObj;
    }
}

module.exports = AJAXForm;